<template>
  <div class="news">
    <HeadView :classA="classANum" headBg="1"></HeadView>
    <div class="main">
      <div class="bannerWrap">
        <img
          alt=""
          class="newsBanner"
          src="@/assets/images/news/newsBanner.png"
        />
        <img alt="" class="newsIcon" src="@/assets/images/news/newsIcon.png" />
      </div>
      <div class="outer">
        <div class="left">
          <ul id="headUl">
            <li class="active">公司动态</li>
            <li>用户心声</li>
            <li>媒体报道</li>
          </ul>
        </div>
        <div class="right">
          <dl id="companyId" class="companyWrap">
            <dt>公司动态</dt>
            <dd>
              <div class="listWrap">
                <router-link to="/newsDetail/goodNews">
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con1.png" />
                  </div>
                  <p>喜报!公司再添增项—获医疗器械网络经营备案资质</p>
                </router-link>
              </div>

              <div class="listWrap">
                <router-link to="/newsDetail/healthRun">
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con2.png" />
                  </div>
                  <p>第四届“朗致杯”健康跑，公益开跑</p>
                </router-link>
              </div>
            </dd>
          </dl>
          <dl id="userVoiceId" class="userVoiceWrap">
            <dt>用户心声</dt>
            <dd>
              <div class="listWrap">
                <router-link to="/newsDetail/userVoice12">
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con3.png" />
                  </div>
                  <p>用户心声|致医云诊室，与之相遇，便不相离</p>
                </router-link>
              </div>
              <div class="listWrap">
                <router-link to="/newsDetail/userVoice11">
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con4.png" />
                  </div>
                  <p>用户心声|电子化管理让诊所走的更长远</p>
                </router-link>
              </div>
            </dd>
          </dl>
          <dl id="mediaId" class="mediaWrap">
            <dt>媒体报道</dt>
            <dd>
              <div class="listWrap">
                <a
                  target="_blank"
                  href="https://v.qq.com/x/page/u03453smf6f.html"
                >
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con5.png" />
                  </div>
                  <p>致医云诊室上新闻联播啦！</p>
                </a>
              </div>
              <div class="listWrap">
                <a
                  target="_blank"
                  href="https://v.qq.com/x/page/l0656u7rj9q.html"
                >
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con7.png" />
                  </div>
                  <p>朗致集团•基层医疗互联网+新趋势广州发布会</p>
                </a>
              </div>
              <div class="listWrap">
                <a
                  target="_blank"
                  href="https://v.qq.com/x/page/v0655y9xrys.html"
                >
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con6.png" />
                  </div>
                  <p>“致医云诊室”落户洛阳，助力基层医疗服务能力提升</p>
                </a>
              </div>
              <div class="listWrap">
                <a
                  target="_blank"
                  href="https://v.youku.com/v_show/id_XMzcxMTM0NzQwNA==.html"
                >
                  <div class="conImg">
                    <img alt="" src="@/assets/images/news/con8.png" />
                  </div>
                  <p>朗致集团基层医疗互联网+新趋势发布会 · 赣州站</p>
                </a>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import "../assets/style/leftNav.css";
</style>
<style lang="scss" scoped>
.news {
  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;

    .outer {
      .right {
        dl {
          dt {
            font-size: 18px;
            color: #333;
            text-align: left;
            padding: 0 0 15px 15px;
            font-weight: bold;
          }
          dd {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 20px;
            a {
              display: block;
              width: 100%;
              height: 100%;
            }
            .listWrap {
              width: 49%;
              background: #fff;
              margin-bottom: 20px;
              .conImg {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                img {
                  width: 100%;
                }
              }
              p {
                font-size: 16px;
                color: #333;
                padding: 20px 19px;
                text-align: left;
              }
            }
          }
        }
        dl.mediaWrap {
          dd {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .main {
      width: 90%;
    }
  }
}
</style>
<script>
import Foot from "@/components/common/Foot";
import HeadView from "@/components/common/Head";
import "../assets/js/leftNav.js";

export default {
  name: "news",
  components: {
    HeadView,
    Foot,
  },
  data() {
    return {
      classANum: "3",
    };
  },
  methods: {},
  mounted() {},
};
</script>
